import React from 'react';
import Script from 'next/script';

import IFrame from '../IFrame';

import type { IFrameProps } from '../IFrame/IFrame.types';

const Navattic = (props: IFrameProps) => {
  const customProps = { id: 'navattic-embed', allow: 'fullscreen' };

  const { iFrameUrl } = props;

  const [finalIFrameUrl, setFinalIFrameUrl] = React.useState<string | undefined>(iFrameUrl);

  React.useEffect(() => {
    if (iFrameUrl) {
      const queryParams = new URLSearchParams(window.location.search);
      const url = new URL(iFrameUrl);
      queryParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });
      setFinalIFrameUrl(url.toString());
    }
  }, [iFrameUrl]);

  return (
    <>
      <IFrame {...props} iFrameUrl={finalIFrameUrl} customProps={customProps} />

      <Script
        id={`navattic-embed-events`}
        src="https://js.navattic.com/embed-events.js"
        onReady={() => {
          void 0 === window.navatticQueue &&
            ((window.navatticQueue = []),
            (window.navattic = new Proxy(
              {},
              {
                get: function n(t, u, e) {
                  return function () {
                    for (var n = arguments.length, t = Array(n), e = 0; e < n; e++)
                      t[e] = arguments[e];
                    return navatticQueue.push({ function: u, arguments: t });
                  };
                }
              }
            )));
        }}
      />

      {/* <Script id="navattic-embed-events-queue" strategy="afterInteractive">
        {`void 0===window.navatticQueue&&(window.navatticQueue=[],window.navattic=new Proxy({},{get:function n(t,u,e){return function(){for(var n=arguments.length,t=Array(n),e=0;e<n;e++)t[e]=arguments[e];return navatticQueue.push({function:u,arguments:t})}}}));`}
      </Script> */}
    </>
  );
};

export default Navattic;
